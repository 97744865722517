<script>
  import { afterNavigate } from "$app/navigation";
  import SearchMobile from "$lib/admin/components/blocks/helpers/SearchMobile.svelte";
  import SearchDesktop from "$lib/admin/components/blocks/helpers/SearchDesktop.svelte";
  import DarkMode from "$lib/main/DarkMode.svelte";
  import Icons from "$lib/main/components/Icons.svelte";
  import { isAuthenticated, project } from "$lib/services/store";
  import { loginAuth0, logoutAuth0 } from "$lib/services/auth0";
  import AuthProvider from "$lib/main/AuthProvider.svelte";
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";
  import { onMount } from "svelte";

  let innerWidth = 0;
  let opened = false;
  let scrollY = 76;

  let dropdownStates = [];

  function toggleDropdown(index) {
    dropdownStates = dropdownStates.map((state, i) => (i === index ? !state : false));
  }

  $: menuItems = $project?.menu?.menu ?? [];
  $: dropdownStates = Array(menuItems.length).fill(false);

  const isAuth0Enabled = import.meta.env.VITE_ENABLE_AUTH0 === "true";

  onMount(() => {
    const handleScroll = () => {
      scrollY = (window.scrollY || document.documentElement.scrollTop) + 76;
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  $: {
    if (opened) {
      document.documentElement.style.setProperty("--mobile-menu-top", `${scrollY}px`);
    }
  }

  const disableBodyScroll = () => {
    if (typeof window !== "undefined") {
      document.body.classList.add("no-scroll");
    }
  };

  const enableBodyScroll = () => {
    if (typeof window !== "undefined") {
      document.body.classList.remove("no-scroll");
    }
  };

  $: if (opened) {
    disableBodyScroll();
  } else {
    enableBodyScroll();
  }

  async function login() {
    const tournamentRoute = $project.settings.tournamentsUrl ?? "turneringar";
    const redirectPathName = window.location.pathname.includes(`/${tournamentRoute}`)
      ? `/${tournamentRoute}`
      : "/profile";
    await loginAuth0(redirectPathName);
  }

  async function signup() {
    const tournamentRoute = $project.settings.tournamentsUrl ?? "turneringar";
    await loginAuth0(`/${tournamentRoute}`, "signup");
  }

  async function logout() {
    logoutAuth0();
  }

  function toggleNav() {
    opened = !opened;
  }

  afterNavigate(() => {
    opened = false;
  });

  onMount(() => {
    // PROFILE BUTTON
    // Timeout to make sure the modal is open before focusing the input, otherwise it won't work
    setTimeout(() => {
      const dropdownMenuProfile = Array.from(document.querySelectorAll(".profile-menu-content"))[0];
      const dropdownButtonProfile = Array.from(document.querySelectorAll(".nav-login"))[0];
      if (dropdownMenuProfile) {
        dropdownMenuProfile.style.setProperty("display", "block");
        const rect = dropdownMenuProfile.getBoundingClientRect();
        const viewportWidth = window.innerWidth;

        dropdownMenuProfile.style.right = "";

        if (rect.right > viewportWidth - 10) {
          dropdownMenuProfile.style.right = `${viewportWidth - dropdownButtonProfile.getBoundingClientRect().right - 10}px`;
        }
        dropdownMenuProfile.style.removeProperty("display");
      }
    }, 100);

    // NAV ITEMS BUTTON
    // Timeout to make sure the modal is open before focusing the input, otherwise it won't work
    setTimeout(() => {
      const dropdownMenuNav = Array.from(document.querySelectorAll(".subnav-content"));
      const dropdownButton = Array.from(document.querySelectorAll(".subnavbtn"));
      dropdownMenuNav.forEach((dropdown, index) => {
        dropdown.style.setProperty("display", "block");
        const rect = dropdown.getBoundingClientRect();
        const viewportWidth = window.innerWidth;

        dropdown.style.right = "";
        dropdown.style.left = "0";

        if (rect.right > viewportWidth - 10) {
          dropdown.style.left = "";
          dropdown.style.right = `${viewportWidth - dropdownButton[index].getBoundingClientRect().right}px`;
        }
        dropdown.style.removeProperty("display");
      });
    }, 100);
  });
</script>

<svelte:window bind:innerWidth />

<AuthProvider>
  <header class="style-a" style="overflow: visible;background-color: #A02D28">
    <div class="container" style="padding-top: 10px; padding-bottom: 10px; position: relative">
      <span role="button" tabindex="0" class="mobile-hamburger" on:click={toggleNav}>
        <div class:opened></div>
      </span>

      <a href="/" style="display: flex; align-items: center; ">
        <img
          class="logo-img"
          alt={$project.name + " Logo"}
          src={$project.data?.imageFolders?.assets + $project.name_lower + "-logo.svg?h=22&q=100&auto=format"} />
      </a>

      <div class="navigation">
        {#if $project.menu?.darkMode === "active"}
          <DarkMode key={$project.key} />
        {/if}

        {#if $project.menu?.search == "active"}
          <div class="search-wrapper-mobile">
            <SearchMobile />
          </div>
          <div class="search-wrapper-desktop">
            <SearchDesktop />
          </div>
        {/if}

        {#if isAuth0Enabled}
          {#if $isAuthenticated}
            <div class="nav-login">
              <div class="profile-button">
                <Icons name="user" width="20px" height="20px" fill="currentColor" color="currentColor" />
                <Translate text="Profile" />
                <Icons name="arrow-right" width="8px" height="8px" color="currentColor" />
              </div>
              <div class="profile-menu-content">
                <a href="/profile"> <Translate text="Edit profile" /></a>
                <a href={null} on:click={() => logout()}>
                  <Icons name="log-out" width="20px" height="20px" strokeWidth="2" color="currentColor" />
                  <Translate text="Log out" />
                </a>
              </div>
            </div>
          {:else}
            <a href={null} class="nav-login" on:click={() => login()}>
              <Icons name="user" width="20px" height="20px" fill="currentColor" color="currentColor" />
              <span class="text"><Translate text="Log in" /></span>
            </a>
          {/if}
        {/if}
      </div>
    </div>

    <!-- MOBILE -->
    <div class="backdrop" class:opened on:click={() => (opened = false)}></div>
    <nav class="mobile-slider-menu" class:opened>
      <div class="sidenav">
        {#if $project.menu?.menu}
          {#each menuItems as navitem, i}
            {#if navitem.subitems}
              <button class="dropdown-btn" on:click={() => toggleDropdown(i)} class:active={dropdownStates[i]}>
                {navitem.name}
                {#if dropdownStates[i]}
                  <Icons
                    class="rotate-ninty"
                    name="arrow-right"
                    width="24px"
                    height="20px"
                    color="currentColor"
                    strokeWidth="2" />
                {:else}
                  <Icons name="arrow-right" width="18px" height="18px" color="currentColor" strokeWidth="2" />
                {/if}
              </button>
              <div class="dropdown-container" class:active={dropdownStates[i]}>
                {#each navitem.subitems as subitem}
                  <a href={subitem.path}>{subitem.name}</a>
                {/each}
              </div>
            {:else}
              <a href={navitem.path} on:click={() => (opened = false)}>{navitem.name}</a>
            {/if}
          {/each}
        {/if}
      </div>
      <div class="login-register-div">
        <button on:click={() => login()}><Translate text="Log in" /></button>
        <button on:click={() => signup()}><Translate text="Register" /></button>
      </div>

      {#if $project.menu.footerMenu.socials}
        <div class="block socials-div">
          <div class="container">
            <div class="grid">
              <div class="g-col-12">
                <div class="social-div">
                  {#each $project.menu.footerMenu.socials as social}
                    <a
                      class={"_" + social.platform}
                      href={social.url}
                      target="_blank"
                      rel="nofollow noreferrer noopener"
                      ><Icons name={social.platform} strokeWidth="1.5" width="24px" height="24px" color="white" /></a>
                  {/each}
                </div>
              </div>
            </div>
          </div>
        </div>
      {/if}
    </nav>

    <!-- DESKTOP -->
    <nav class="nav-bar-desktop" style="">
      <div class="container">
        <div class="nav-container">
          {#if $project.menu?.menu}
            {#each $project?.menu.menu ?? [] as navitem}
              <div class="nav-item-div">
                {#if navitem.subitems}
                  <div class="subnav">
                    <button class="subnavbtn">
                      {navitem.name}
                      <Icons width="16px" height="8px" name="arrow-down-full" fill="white" color="white" />
                    </button>
                    <div class="subnav-content">
                      {#each navitem.subitems as subitem}
                        <a href={subitem.path}>{subitem.name}</a>
                      {/each}
                    </div>
                  </div>
                {:else}
                  <a class="nav-item" href={navitem.path} on:click={() => (opened = false)}>{navitem.name}</a>
                {/if}
              </div>
            {/each}
          {/if}
        </div>
      </div>
    </nav>
  </header>
</AuthProvider>

<style>
  body.no-scroll {
    overflow: hidden;
  }

  .nav-bar-desktop {
    display: none;
    width: 100%;
    background-color: #c03832;
    z-index: 3;
    @media (min-width: 992px) {
      display: flex;
    }
  }
  .nav-container {
    width: 100%;
    height: 60px;
    display: flex;
    gap: 14px;
    @media (min-width: 1200px) {
      gap: 28px;
    }
  }
  .logo-img {
    width: 160px;
    height: 25px;
    margin: auto 0;
    @media (min-width: 1200px) {
      width: 200px;
      height: 35px;
    }
  }
  .nav-item-div {
    height: 100%;
    padding: 0;
    display: flex;
    justify-content: flex-start;
  }
  .nav-login {
    padding: 1rem 0;
    font-size: 0.9375rem;
    font-weight: 700;
    color: var(--clr-pri-txt);
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      color: var(--clr-pri-txt-hvr);
    }

    .text {
      display: none;

      @media (min-width: 992px) {
        display: inline;
      }
   }
  }
  .nav-item {
    position: relative;
    color: var(--clr-pri-txt);
    font-size: 15px;
    font-weight: 700;
    width: fit-content;
    text-align: left;
    white-space: nowrap;
    display: flex;
    align-items: center;
    &:hover {
      color: #eaeaea;
    }
    @media (min-width: 1200px) {
      font-size: 16px;
    }
  }

  .backdrop {
    position: fixed;
    top: var(--mobile-menu-top, 0px);
    width: 100vw;
    height: calc(100vh - 72px);
    background-color: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: opacity 300ms ease;
    will-change: opacity;
    z-index: 1;
    &.opened {
      opacity: 1;
      visibility: visible;
    }
  }

  .mobile-slider-menu {
    position: fixed;
    top: var(--mobile-menu-top, 0px);
    display: flex;
    flex-flow: column;
    overflow-y: auto;
    border-bottom: 1px solid var(--bd-clr);
    z-index: 2;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    transform: translateX(-200%);
    transition: transform 0.4s ease;
    will-change: transform;
    background-color: #c03832;

    &.opened {
      transform: translateX(0);
    }

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0;
      margin: 0 auto;
      padding: 0 1rem;
      margin-top: 0;
      margin-bottom: 0;

      li {
        padding: 0;
        width: 100%;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        border-bottom: 1px solid var(--bg-clr-alt-lt);

        justify-content: flex-start;

        a {
          padding: 0.5rem 1rem;
          border-radius: var(--bd-rad);
          text-decoration: none;

          padding: 1rem 0;
          color: var(--clr-pri-txt);
          font-size: 0.9375rem;
          font-weight: 700;
          width: 100%;
          text-align: left;
          &:hover {
            color: var(--clr-pri-txt-hvr);
          }
        }
      }
    }
    @media (min-width: 992px) {
      display: none;
    }
  }

  .subnav {
    float: left;
    position: relative;
    .subnavbtn {
      font-size: 15px;
      font-weight: 700;
      padding: 0;
      border: none;
      outline: none;
      color: white;
      cursor: pointer;
      height: 100%;
      width: 100%;
      white-space: nowrap;
      background-color: transparent;
      font-family: inherit;
      margin: 0;
      gap: 3px;
      display: flex;
      align-items: center;
      &:hover {
        color: #eaeaea;
      }
      @media (min-width: 1200px) {
        font-size: 16px;
      }
    }
  }

  .subnav-content {
    display: none;
    position: absolute;
    top: 60px;
    background-color: white;
    width: fit-content;
    max-width: 350px;
    min-width: 200px;
    z-index: 4;
    padding: 2px 2px;
    box-shadow: 0px -4px 15px 0px rgba(0, 0, 0, 0.25);

    a {
      padding: 16px;
      float: left;
      color: #121212;
      text-decoration: none;
      font-size: 14px;
      line-height: 130%;
      &:hover {
        background-color: #e54038;
        color: white;
      }
    }
  }

  .subnav:hover .subnav-content {
    display: flex;
    flex-flow: column;
  }

  .profile-button {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
  }

  .profile-menu-content {
    display: none;
    position: fixed;
    background-color: white;
    top: 60px;
    margin-right: 10px;
    width: 200px;
    z-index: 4;
    padding: 2px 2px;
    a {
      padding: 18px 10px;
      float: left;
      color: #121212;
      text-decoration: none;
      font-size: 14px;
      font-weight: 700;
      line-height: 130%;
      display: flex;
      align-items: center;
      gap: 4px;
      &:hover {
        background-color: #e54038;
        color: white;
      }
    }
  }

  .nav-login:hover .profile-menu-content {
    display: flex;
    flex-flow: column;
  }

  .mobile-hamburger {
    cursor: pointer;
    border: 0px;
    padding: 0px;
    appearance: none;
    font-size: 1rem;
    background-color: transparent;
    text-align: left;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    width: 34px;
    height: 34px;
    padding: 5px;
    outline-offset: 2px;
    outline: none;
    display: flex;
    align-items: center;
    align-self: center;
    justify-self: flex-end;
    div {
      display: block;
      width: 24px;
      height: 2px;
      background-color: #fff;
      outline: transparent solid 1px;
      transition-property: background-color, transform;
      transition-duration: 300ms;
      position: relative;
      user-select: none;
      box-sizing: border-box;
      &:before,
      &:after {
        display: block;
        width: 24px;
        height: 2px;
        background-color: #fff;
        outline: transparent solid 1px;
        transition-property: background-color, transform;
        transition-duration: 300ms;
        position: absolute;
        content: "";
        left: 0px;
        top: 8px;
      }
      &:before {
        top: -8px;
      }
      &.opened {
        background-color: transparent;
        &:before {
          transform: translateY(8px) rotate(45deg);
        }
        &:after {
          transform: translateY(-8px) rotate(-45deg);
        }
      }
    }
    @media (min-width: 992px) {
      display: none;
    }
  }

  .search-wrapper-mobile {
    display: flex;
    align-items: center;
    @media (min-width: 992px) {
      display: none;
    }
  }
  .search-wrapper-desktop {
    display: none;
    @media (min-width: 992px) {
      display: block;
      margin-right: 10px;
    }
  }

  .dropdown-container {
    display: none;
    padding-left: 10px;

    a {
      padding: 10px 8px 10px 16px !important;
      margin-top: 2px;
      color: #3f3f3f !important;
      height: fit-content !important;
      min-height: 50px;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 1.1px;
    }
  }

  .sidenav {
    height: fit-content;
    width: 100%;
    z-index: 1;
    overflow-x: hidden;
  }

  .sidenav a,
  .dropdown-btn {
    padding: 0 16px 0 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
    color: white;
    height: 50px;
    font-size: 14px;
    font-weight: 700;
    margin: 0;

    &:first-child {
      border-top: none;
    }
    &:last-child {
      border-bottom: none;
    }
  }

  .sidenav .dropdown-btn {
    background-color: #ac3227;
    border-top: 1px solid #982d23;
    border-bottom: 1px solid #982d23;
    &:first-of-type {
      border-top: none;
    }
    &:last-of-type {
      border-bottom: none;
    }
  }

  .dropdown-btn.active {
    background-color: #e54038;
    color: white;
  }

  .dropdown-container.active {
    display: block;
    background-color: white;
  }

  .socials-div {
    .social-div {
      display: flex;
      width: 100%;
      justify-content: center;
      gap: 20px;
      color: white;
      a {
        color: #fff !important;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }

  .login-register-div {
    display: flex;
    gap: 12px;
    justify-content: center;
    margin: 25px 0;

    button {
      height: 45px;
      width: 150px;
      border-radius: 60px;
      border: 1px solid white;
      font-size: 16px;
      font-weight: 700;
      &:first-of-type {
        background-color: white;
        color: #e54038;
      }
      &:last-of-type {
        background-color: transparent;
        color: white;
      }
    }
  }

  ._facebook {
    background-color: #37589b !important;
  }
  ._twitter {
    background-color: #31abe1 !important;
  }
  ._instagram {
    background-color: #833ab4 !important;
    background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d) !important;
  }
  ._youtube {
    background-color: #ff0000 !important;
  }
  ._gmail {
    background-color: #fff !important;
  }
  ._linkedin {
    background-color: #0077b5 !important;
  }
</style>
